














































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { vxm } from '@/store'
import SortableList from '@/components/list/SortableList.vue'
import { Watch } from 'vue-property-decorator'

const sortableList = new SortableList()

@Component({
  components: {
    ListView,
  },
  directives: {
    sortableList,
  },
})
export default class List extends Vue {
  private headers = []
  private routes = {}
  private topActions = []
  private showDeleted = this.getDefaults().showDeleted
  private loading = false
  private categories = []
  private sortEnabled = false
  private listComponentKey = 'services-list'

  public created(): void {
    this.onRouteChange()
  }

  public mounted(): void {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
        filter: { focus: true },
      },
      {
        text: 'Duration (min)',
        value: 'duration',
      },
      {
        text: 'Sales price',
        value: 'profitStaticPrice',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Visibility',
        value: 'visibility',
      },
      {
        // todo: resolve name in backend
        text: 'Category',
        value: 'category.name',
      },
      {
        text: 'Mode',
        value: 'mode',
      },
      {
        text: 'Sort order',
        value: 'sortOrder',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: this.isAddon ? 'Settings/Booking/AddonServices/Edit' : 'Settings/Booking/Services/Edit',
            params: { calendarId: this.$route.params.calendarId, id: '0', isNew: true },
          }
        },
      },
      {
        id: 'sort',
        label: 'Change sort order',
        click: () => {
          this.sortEnabled = true
        },
      },
    ]

    this.loadServiceCategories()
  }

  private cancelSortOrder() {
    this.sortEnabled = false
    this.listComponentKey = 'services-list-' + new Date().getTime()
  }

  private saveSortOrder() {
    const payload = []
    const elems = document.getElementsByClassName('sort-item-id')
    let sortOrder = 0
    for (let i = 0; i < elems.length; i++) {
      const id = elems[i].innerHTML.trim()
      sortOrder++
      payload.push({ id, sortOrder })
    }
    this.$axios
      .post('/v4/site/calendars/' + this.$route.params.calendarId + '/services/many/sortOrders', { items: payload })
      .then((_response) => {
        this.sortEnabled = false
        this.listComponentKey = 'services-list-' + new Date().getTime()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to save sort orders')
      })
  }

  private saveSortOrderAlphabetically() {
    this.$axios
      .post(
        '/v4/site/calendars/' +
          this.$route.params.calendarId +
          '/services/many/sortAlphabetically?isAddon=' +
          (this.isAddon ? '1' : ''),
      )
      .then((_response) => {
        this.sortEnabled = false
        this.listComponentKey = 'services-list-' + new Date().getTime()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to save sort orders')
      })
  }

  private loadServiceCategories() {
    this.$axios
      .get('/v4/site/products/categories')
      .then((response) => {
        this.categories = response.data.data
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err)
      })
  }

  private get rowActions() {
    const actions: any = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: this.isAddon ? 'Settings/Booking/AddonServices/Edit' : 'Settings/Booking/Services/Edit',
            params: { calendarId: this.$route.params.calendarId, id: item.id },
          }
        },
      },
    ]
    if (this.showDeleted) {
      actions.push({
        id: 'undelete',
        label: 'Undelete',
        click: async (parent, item) => {
          await this.$axios
            .put(this.url + '/' + item.id + '/undelete')
            .then(() => {
              parent.load()
            })
            .catch((err) => {
              vxm.alert.onAxiosError(err)
            })
        },
      })
    } else {
      actions.push({ id: 'delete' })
    }
    return actions
  }

  private get isAddon() {
    return !!this.$route.meta?.isAddonService
  }

  private get query() {
    const data = {
      showDeleted: this.showDeleted ? 1 : 0,
    }

    if (!this.showDeleted) {
      delete data.showDeleted
    }

    return data
  }

  private get url() {
    return '/v4/site/calendars/' + this.$route.params.calendarId + '/services'
  }

  @Watch('$route', { deep: true })
  private onRouteChange(): void {
    const defaults = this.getDefaults()
    const query = this.$route.query || {}
    if (query.showDeleted === undefined) {
      this.showDeleted = defaults.showDeleted
    } else {
      this.showDeleted = parseInt(query.showDeleted.toString()) === 1
    }
  }

  private getDefaults() {
    return {
      showDeleted: false,
    }
  }

  private get title() {
    return this.isAddon ? 'Addon services' : 'Services'
  }

  private get defaultSearch() {
    return this.isAddon ? 'type:!=1' : 'type:=1'
  }
}
